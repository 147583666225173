<template>
  <q-page class=" q-pa-lg ">

    <!-- DIALOGO PARA CREAR UN NUEVO -->
    <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
      <Indicadores @cierraVentana="dialogNuevo = false"></Indicadores>
    </q-dialog>

    <div class="row q-col-gutter-sm q-px-xl q-mb-xl">
      <div class=" col-12 col-md-9">
        <q-select filled dense v-model="selectedItem" :options="itemsItems" label="Indicador" />
      </div>
      <div class=" col-12 col-md-3">
        <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año">
          <template v-slot:after>
            <q-btn round color="primary" icon="mdi-magnify" @click="consultar()">
              <q-tooltip>
                Consultar
              </q-tooltip>
            </q-btn>
            <q-btn v-if="token.group == 'admin'" round color="green" icon="mdi-calculator" @click="crearNuevo()">
              <q-tooltip>
                Registrar
              </q-tooltip>
            </q-btn>
          </template>
        </q-select>
      </div>
      <div class=" col-12">
        <chart-component :chartData="chartData" :chartTitle="charTitleE"></chart-component>
      </div>
    </div>


    <!-- <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="mdi-file-pdf-box" color="red" @click="exportToPDF()">
        <q-tooltip>
          Generar PDF
        </q-tooltip>
      </q-btn>
    </q-page-sticky> -->
  </q-page>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import moment from 'moment';
import print from 'print-js'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import { Reporte } from '../components/PDF/ReportePDF.js'
import Indicadores from '../components/Indicadores/Indicadores.vue'
import ChartComponent from "../components/Graficas/ChartComponent.vue";
import jsPDF from 'jspdf';

export default {
  name: 'Home',
  components: {
    ChartComponent,
    HelloWorld, Money, Indicadores
  },
  data() {
    return {

      chartData: null,
      charTitleE: '',
      itemsItems: [
        'VENTAS',
        'CRECIMIENTO',
        'TASA DE VENTAS POR VENDEDOR',
        'TASA DE CRECIMIENTO DE VENTAS',
        'CLIENTES PERDIDOS VS CLIENTES NUEVOS',
        'FACTURACIÓN',
        'TICKET PROMEDIO',
        'COSTO DE ADQUISICIÓN DE CLIENTES',
      ],
      selectedItem: 'VENTAS',
      itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
      itemsMes: [
        { label: 'ENERO', value: 1 },
        { label: 'FEBRERO', value: 2 },
        { label: 'MARZO', value: 3 },
        { label: 'ABRIL', value: 4 },
        { label: 'MAYO', value: 5 },
        { label: 'JUNIO', value: 6 },
        { label: 'JULIO', value: 7 },
        { label: 'AGOSTO', value: 8 },
        { label: 'SEPTIEMBRE', value: 9 },
        { label: 'OCTUBRE', value: 10 },
        { label: 'NOVIEMBRE', value: 11 },
        { label: 'DICIEMBRE', value: 12 },
      ],
      selectedAnio: '2024',
      selectedMes: { label: 'DICIEMBRE', value: 12 },



      dialogNuevo: false,
    }
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  created() { },
  methods: {
    crearNuevo() {
      this.dialogNuevo = true
    },
    async consultar() {
      this.$store.state.nombrePDF = this.selectedItem
      this.$store.state.fechaPDF = this.selectedAnio
      switch (this.selectedItem) {
        case 'VENTAS':
          this.GetListVentas()
          break;
        case 'CRECIMIENTO':
          this.GetListCrecimiento();
          break;
        case 'TASA DE VENTAS POR VENDEDOR':
          this.GetListTasaVentasVendedor();
          break;
        case 'TASA DE CRECIMIENTO DE VENTAS':
          this.GetListTasaCrecimientoVentas();
          break;
        case 'CLIENTES PERDIDOS VS CLIENTES NUEVOS':
          this.GetListClientesPerdidosNuevos();
          break;
        case 'FACTURACIÓN':
          this.GetListFacturacion();
          break;
        case 'TICKET PROMEDIO':
          this.GetListTicketPromedio();
          break;
        case 'COSTO DE ADQUISICIÓN DE CLIENTES':
          this.GetListCostoAdquisicionClientes();
          break;
      }
    },

    async GetListVentas() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListVentas/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Ventas'
        await this.GetGrafica();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    async GetListCrecimiento() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListCrecimiento/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Crecimiento'
        await this.GetGrafica1();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },


    async GetListTasaVentasVendedor() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListTasaVentasVendedor/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Tasa de ventas por vendedor'
        await this.GetGrafica2();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    async GetListTasaCrecimientoVentas() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListTasaCrecimientoVentas/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Tasa de crecimiento de ventas'
        await this.GetGrafica3();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    async GetListClientesPerdidosNuevos() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListClientesPerdidosNuevos/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Clientes perdidos vs clientes nuevos'
        await this.GetGrafica4();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    async GetListFacturacion() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListFacturacion/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Facturación'
        await this.GetGrafica5();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    async GetListTicketPromedio() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListTicketPromedio/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Ticket promedio'
        await this.GetGrafica6();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    async GetListCostoAdquisicionClientes() {
      this.dialogtext = 'Consultando, espere';
      this.dialog = true;

      try {
        let response = await axios.get('Indicadores/GetListCostoAdquisicionClientes/' + this.selectedAnio);
        let x = response.data;
        console.log(x)
        this.dataResult = x;
        this.dialog = false;
        this.charTitleE = 'Costo de adquisición de clientes'
        await this.GetGrafica7();
      } catch (error) {
        console.log(error)
        this.dialog = false;
      }
    },

    FormatoMiles(value) {
      return value.toLocaleString('en-US');
    },

    async GetGrafica() {
      const ventas = this.dataResult.map((item) => item.ventas);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.ventas, 0);

      let ObjVentas = {
        label: "Ventas: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Ventas: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica1() {
      const ventas = this.dataResult.map((item) => item.crecimiento);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.crecimiento, 0);

      let ObjVentas = {
        label: "Crecimiento: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Crecimiento: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica2() {
      const ventas = this.dataResult.map((item) => item.tasaVentas);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.tasaVentas, 0);

      let ObjVentas = {
        label: "Tasa de ventas por vendedor: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Tasa de ventas por vendedor: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica3() {
      const ventas = this.dataResult.map((item) => item.tasaCrecimiento);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.tasaCrecimiento, 0);

      let ObjVentas = {
        label: "Tasa de crecimiento de ventas: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Tasa de crecimiento de ventas: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica4() {
      const ventas = this.dataResult.map((item) => item.rotacionClientes);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.rotacionClientes, 0);

      let ObjVentas = {
        label: "Clientes perdidos vs clientes nuevos: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Clientes perdidos vs clientes nuevos: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica5() {
      const ventas = this.dataResult.map((item) => item.facturacion);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.facturacion, 0);

      let ObjVentas = {
        label: "Facturación: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Facturación: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica6() {
      const ventas = this.dataResult.map((item) => item.ticketPromedio);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.ticketPromedio, 0);

      let ObjVentas = {
        label: "Ticket promedio: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Ticket promedio: ' + this.FormatoMiles(sumaVentas)
    },

    async GetGrafica7() {
      const ventas = this.dataResult.map((item) => item.cac);
      const sumaVentas = this.dataResult.reduce((acumulador, actual) => acumulador + actual.cac, 0);

      let ObjVentas = {
        label: "Costo de adquisición de clientes: " + this.FormatoMiles(sumaVentas),
        backgroundColor: "rgba(0, 163, 92, 0.5)",
        borderColor: "rgba(0, 163, 92)",
        borderWidth: 1,
        data: ventas,
      }

      let chartDatas = {
        labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
        datasets: []
      }
      chartDatas.datasets.push(ObjVentas)
      this.chartData = { ...chartDatas }

      this.charTitleE = 'Costo de adquisición de clientes: ' + this.FormatoMiles(sumaVentas)
    },

    async GenerarPdf() {
      let objeto = {
        ventas: {
          metaVentasMes: this.metaVentasMes,
          ventasMes: this.ventasMes,
          ventas: this.ventas,
        },
        crecimiento: {
          ingresoMesActual: this.ingresoMesActual,
          ingresoMesAnterior: this.ingresoMesAnterior,
          crecimiento: this.crecimiento,
        },
        tasaVentas: {
          numVentasTotal: this.numVentasTotal,
          numVentasVendedor: this.numVentasVendedor,
          tasaVentas: this.tasaVentas,
        },
        tasaCrecimiento: {
          ventasActual: this.ventasActual,
          ventasAnterior: this.ventasAnterior,
          tasaCrecimiento: this.tasaCrecimiento,
        },
        rotacionClientes: {
          clientesPerdidos: this.clientesPerdidos,
          clientes: this.clientes,
          rotacionClientes: this.rotacionClientes,
        },
        facturacion: {
          facturacionActual: this.facturacionActual,
          facturacionPasado: this.facturacionPasado,
          facturacion: this.facturacion,
        },
        ticketPromedio: {
          facturacionBruta: this.facturacionBruta,
          volumenVentas: this.volumenVentas,
          ticketPromedio: this.ticketPromedio,
        },
        cac: {
          sueldoVentas: this.sueldoVentas,
          sueldoMarketing: this.sueldoMarketing,
          gastosPublicidad: this.gastosPublicidad,
          nuevosClientes: this.nuevosClientes,
          cac: this.cac
        }
      }
      let base64 = await Reporte(objeto);
      console.log(base64)
      let documento = base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })
    },


  }
}
</script>
