<template>
    <q-card style="width: 1000px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Indicadores
                </q-toolbar-title>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section class="scroll">
            <div class="row  q-col-gutter-sm q-px-xl q-mb-lg">
                <div class=" col-12 col-md-6">
                    <q-select filled dense v-model="selectedItem" :options="itemsItems" label="Indicador" />
                </div>
                <div class=" col-12 col-md-2">
                    <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año" />
                </div>
                <div class="col-12 col-md-4">
                    <q-select filled dense v-model="selectedMes" :options="itemsMes" label="Mes">
                        <template v-slot:after>
                            <q-btn dense round color="primary" icon="mdi-magnify" class="q-mr-sm" @click="consultar()">
                                <q-tooltip>
                                    Buscar
                                </q-tooltip>
                            </q-btn>
                            <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm" @click="guardar()">
                                <q-tooltip>
                                    Guardar
                                </q-tooltip>
                            </q-btn>
                        </template>
                    </q-select>
                </div>
            </div>
            <div class="row q-col-gutter-sm q-px-xl q-mb-xl">
                <!-- VENTAS -->
                <template v-if="selectedItem == 'VENTAS'">
                    <div class="col-12">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>VENTAS</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;"> Meta de ventas mensuales/Ventas realizadas al mes x
                            100
                            =
                            Ventas</span>

                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Meta de ventas mensuales" v-model="metaVentasMes">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ventas realizadas al mes" v-model="ventasMes">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled :value="100" readonly>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularVentas()">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ventas" v-model="ventas">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarVentas()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- CRECIMIENTO -->
                <template v-if="selectedItem == 'CRECIMIENTO'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>CRECIMIENTO</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">(Ingresos mes en curso – ingresos mes anterior) /
                            Ingresos mes
                            anterior X 100</span>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ingresos mes en curso" v-model="ingresoMesActual">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ingresos mes anterior" v-model="ingresoMesAnterior">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled :value="100" readonly>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularCrecimiento">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Crecimiento" v-model="crecimiento">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarCrecimiento()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- TASA DE VENTAS POR VENDEDOR -->
                <template v-if="selectedItem == 'TASA DE VENTAS POR VENDEDOR'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>TASA DE VENTAS POR VENDEDOR</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">Número de ventas del vendedor en un periodo
                            determinado /
                            Número
                            ventas totales en el mismo periodo X 100</span>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ingresos mes en curso" v-model="numVentasVendedor">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ingresos mes en curso" v-model="numVentasTotal">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled :value="100" readonly>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularTasaVentas()">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Tasa de ventas por vendedor" v-model="tasaVentas">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarTasaVentas()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- TASA DE CRECIMIENTO DE VENTAS -->
                <template v-if="selectedItem == 'TASA DE CRECIMIENTO DE VENTAS'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>TASA DE CRECIMIENTO DE VENTAS</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">(Ventas periodo actual – ventas del periodo anterior)
                            /
                            ventas
                            periodo anterior X 100.</span>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Ventas periodo actual" v-model="ventasActual">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="ventas del periodo anterior" v-model="ventasAnterior">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled :value="100" readonly>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularTasaCrecimiento()">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Tasa de crecimiento de ventas" v-model="tasaCrecimiento">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarTasaCrecimiento()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- CLIENTES PERDIDOS VS CLIENTES NUEVOS -->
                <template v-if="selectedItem == 'CLIENTES PERDIDOS VS CLIENTES NUEVOS'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>CLIENTES PERDIDOS VS CLIENTES NUEVOS</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">(Número total de clientes perdidos) / (Número total
                            de
                            clientes a
                            principio de mes) X 100 = %Rotación de clientes perdidos.</span>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Número total de clientes perdidos" v-model="clientesPerdidos">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Número total de clientes a principio de mes" v-model="clientes">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled :value="100" readonly>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularClientes()">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Rotación de clientes perdidos" v-model="rotacionClientes">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarClientes()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- FACTURACIÓN -->
                <template v-if="selectedItem == 'FACTURACIÓN'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>FACTURACIÓN</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">(Facturación mes actual – facturación mes pasado) /
                            (Facturación
                            mes pasado) * 100 = facturación mensual.</span>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Facturación mes actual" v-model="facturacionActual">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Facturación mes pasado" v-model="facturacionPasado">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled :value="100" readonly>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularFacturacion()">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Facturación mensual" v-model="facturacion">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarFacturacion()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- TICKET PROMEDIO -->
                <template v-if="selectedItem == 'TICKET PROMEDIO'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>TICKET PROMEDIO</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">(Facturación bruta) / (Volumen de ventas) = Ticket
                            promedio.</span>
                    </div>

                    <div class="col-12 col-md-4">
                        <q-field dense filled label="Facturación bruta" v-model="facturacionBruta">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-field dense filled label="Volumen de venta" v-model="volumenVentas">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularTicket">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-field dense filled label="Ticket promedio" v-model="ticketPromedio">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarTicket()" />
                            </template>
                        </q-field>
                    </div>
                </template>

                <!-- COSTO DE ADQUISICIÓN DE CLIENTES  -->
                <template v-if="selectedItem == 'COSTO DE ADQUISICIÓN DE CLIENTES'">
                    <div class="col-12 q-mt-lg">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>COSTO DE ADQUISICIÓN DE CLIENTES</div>
                            <q-space />
                        </q-bar>
                        <span style="color: #767676; font-size: 14px;">(Sueldos equipo de ventas) + (Suelos equipo de
                            marketing)
                            + (Gastos
                            de publicidad) / (Nuevos clientes adquiridos) = CAC</span>
                    </div>

                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Sueldos equipo de ventas" v-model="sueldoVentas">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <q-field dense filled label="Suelos equipo de marketing" v-model="sueldoMarketing">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <q-field dense filled label="Gastos de publicidad" v-model="gastosPublicidad">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <q-field dense filled label="Nuevos clientes adquiridos" v-model="nuevosClientes">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-calculator" @click="calcularCosto">
                                    <q-tooltip>
                                        Calcular
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <q-field dense filled label="CAC" v-model="cac">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-delete-empty" @click="borrarCosto()" />
                            </template>
                        </q-field>
                    </div>
                </template>

            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            itemsItems: [
                'VENTAS',
                'CRECIMIENTO',
                'TASA DE VENTAS POR VENDEDOR',
                'TASA DE CRECIMIENTO DE VENTAS',
                'CLIENTES PERDIDOS VS CLIENTES NUEVOS',
                'FACTURACIÓN',
                'TICKET PROMEDIO',
                'COSTO DE ADQUISICIÓN DE CLIENTES',
            ],
            selectedItem: 'VENTAS',
            itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '2024',
            selectedMes: { label: 'ENERO', value: 1 },

            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },

            metaVentasMes: 0,
            ventasMes: 0,
            ventas: 0,

            ingresoMesActual: 0,
            ingresoMesAnterior: 0,
            crecimiento: 0,

            numVentasTotal: 0,
            numVentasVendedor: 0,
            tasaVentas: 0,

            ventasActual: 0,
            ventasAnterior: 0,
            tasaCrecimiento: 0,

            clientesPerdidos: 0,
            clientes: 0,
            rotacionClientes: 0,

            facturacionActual: 0,
            facturacionPasado: 0,
            facturacion: 0,

            facturacionBruta: 0,
            volumenVentas: 0,
            ticketPromedio: 0,

            sueldoVentas: 0,
            sueldoMarketing: 0,
            gastosPublicidad: 0,
            nuevosClientes: 0,
            cac: 0
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    watch: {

    },
    created() {

    },
    methods: {
        calcularVentas() {
            this.ventas = this.metaVentasMes / this.ventasMes * 100
        },
        borrarVentas() {
            this.ventas = 0
            this.metaVentasMes = 0,
                this.ventasMes = 0
        },
        calcularCrecimiento() {
            this.crecimiento = (this.ingresoMesActual - this.ingresoMesAnterior) / this.ingresoMesAnterior * 100
        },
        borrarCrecimiento() {
            this.crecimiento = 0
            this.ingresoMesActual = 0
            this.ingresoMesAnterior = 0
        },
        calcularTasaVentas() {
            this.tasaVentas = this.numVentasVendedor / this.numVentasTotal * 100
        },
        borrarTasaVentas() {
            this.tasaVentas = 0
            this.numVentasVendedor = 0
            this.numVentasTotal = 0
        },
        calcularTasaCrecimiento() {
            this.tasaCrecimiento = (this.ventasActual - this.ventasAnterior) / this.ventasAnterior * 100
        },
        borrarTasaCrecimiento() {
            this.tasaCrecimiento = 0
            this.ventasActual = 0
            this.ventasAnterior = 0
        },
        calcularClientes() {
            this.rotacionClientes = this.clientesPerdidos / this.nuevosClientes * 100
        },
        borrarClientes() {
            this.rotacionClientes = 0
            this.clientesPerdidos = 0
            this.nuevosClientes = 0
        },
        calcularFacturacion() {
            this.facturacion = (this.facturacionActual - this.facturacionPasado) / this.facturacionPasado
        },
        borrarFacturacion() {
            this.facturacion = 0
            this.facturacionActual = 0
            this.facturacionPasado = 0
        },
        calcularTicket() {
            this.ticketPromedio = this.facturacionBruta - this.volumenVentas
        },
        borrarTicket() {
            this.ticketPromedio = 0
            this.facturacionBruta = 0
            this.volumenVentas = 0
        },
        calcularCosto() {
            this.cac = this.sueldoVentas + this.sueldoMarketing + this.gastosPublicidad / this.nuevosClientes
        },
        borrarCosto() {
            this.cac = 0
            this.sueldoVentas = 0
            this.sueldoMarketing = 0
            this.gastosPublicidad = 0
            this.nuevosClientes = 0
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async consultar() {
            switch (this.selectedItem) {
                case 'VENTAS':
                    this.GetVentas()
                    break;
                case 'CRECIMIENTO':
                    this.GetCrecimiento();
                    break;
                case 'TASA DE VENTAS POR VENDEDOR':
                    this.GetTasaVentasVendedor();
                    break;
                case 'TASA DE CRECIMIENTO DE VENTAS':
                    this.GetTasaCrecimientoVentas();
                    break;
                case 'CLIENTES PERDIDOS VS CLIENTES NUEVOS':
                    this.GetClientesPerdidosNuevos();
                    break;
                case 'FACTURACIÓN':
                    this.GetFacturacion();
                    break;
                case 'TICKET PROMEDIO':
                    this.GetTicketPromedio();
                    break;
                case 'COSTO DE ADQUISICIÓN DE CLIENTES':
                    this.GetCostoAdquisicionClientes();
                    break;
            }
        },

        async guardar() {
            switch (this.selectedItem) {
                case 'VENTAS':
                    this.postVentas()
                    break;
                case 'CRECIMIENTO':
                    this.PostCrecimiento();
                    break;
                case 'TASA DE VENTAS POR VENDEDOR':
                    this.PostTasaVentasVendedor();
                    break;
                case 'TASA DE CRECIMIENTO DE VENTAS':
                    this.PostTasaCrecimientoVentas();
                    break;
                case 'CLIENTES PERDIDOS VS CLIENTES NUEVOS':
                    this.PostClientesPerdidosNuevos();
                    break;
                case 'FACTURACIÓN':
                    this.PostFacturacion();
                    break;
                case 'TICKET PROMEDIO':
                    this.PostTicketPromedio();
                    break;
                case 'COSTO DE ADQUISICIÓN DE CLIENTES':
                    this.PostCostoAdquisicionClientes();
                    break;
            }
        },

        async postVentas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let ventas = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                metaVentasMes: this.metaVentasMes,
                ventasMes: this.ventasMes,
                ventas: this.ventas
            }
            console.log(ventas)
            try {
                let response = await axios.post('Indicadores/PostVenta/', ventas)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostCrecimiento() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let crecimiento = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                ingresoMesActual: this.ingresoMesActual,
                ingresoMesAnterior: this.ingresoMesAnterior,
                crecimiento: this.crecimiento
            }
            console.log(crecimiento)
            try {
                let response = await axios.post('Indicadores/PostCrecimiento/', crecimiento)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostTasaVentasVendedor() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let tasaVentas = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                numVentasTotal: this.numVentasTotal,
                numVentasVendedor: this.numVentasVendedor,
                tasaVentas: this.tasaVentas
            }
            console.log(tasaVentas)
            try {
                let response = await axios.post('Indicadores/PostTasaVentasVendedor/', tasaVentas)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostTasaCrecimientoVentas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let tasaCrecimiento = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                ventasActual: this.ventasActual,
                ventasAnterior: this.ventasAnterior,
                tasaCrecimiento: this.tasaCrecimiento
            }
            console.log(tasaCrecimiento)
            try {
                let response = await axios.post('Indicadores/PostTasaCrecimientoVentas/', tasaCrecimiento)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostClientesPerdidosNuevos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let rotacionClientes = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                metaVentasMes: this.metaVentasMes,
                ventasMes: this.ventasMes,
                rotacionClientes: this.rotacionClientes
            }
            console.log(rotacionClientes)
            try {
                let response = await axios.post('Indicadores/PostClientesPerdidosNuevos/', rotacionClientes)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostFacturacion() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let facturacion = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                facturacionActual: this.facturacionActual,
                facturacionPasado: this.facturacionPasado,
                facturacion: this.facturacion
            }
            console.log(facturacion)
            try {
                let response = await axios.post('Indicadores/PostFacturacion/', facturacion)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostTicketPromedio() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let ticketPromedio = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                facturacionBruta: this.facturacionBruta,
                volumenVentas: this.volumenVentas,
                ticketPromedio: this.ticketPromedio
            }
            console.log(ticketPromedio)
            try {
                let response = await axios.post('Indicadores/PostTicketPromedio/', ticketPromedio)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostCostoAdquisicionClientes() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            let cac = {
                anio: this.selectedAnio,
                mes: this.selectedMes.label,
                item: this.selectedItem,
                sueldoVentas: this.sueldoVentas,
                sueldoMarketing: this.sueldoMarketing,
                nuevosClientes: this.nuevosClientes,
                cac: this.cac
            }
            console.log(cac)
            try {
                let response = await axios.post('Indicadores/PostCostoAdquisicionClientes/', cac)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async GetVentas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetVenta/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.metaVentasMes = response.data.metaVentasMes
                this.ventasMes = response.data.ventasMes
                this.ventas = response.data.ventas

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetCrecimiento() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetCrecimiento/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.ingresoMesActual = response.data.ingresoMesActual
                this.ingresoMesAnterior = response.data.ingresoMesAnterior
                this.crecimiento = response.data.crecimiento

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetTasaVentasVendedor() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetTasaVentasVendedor/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.numVentasTotal = response.data.numVentasTotal
                this.numVentasVendedor = response.data.numVentasVendedor
                this.tasaVentas = response.data.tasaVentas

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetTasaCrecimientoVentas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetTasaCrecimientoVentas/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.ventasActual = response.data.ventasActual
                this.ventasAnterior = response.data.ventasAnterior
                this.tasaCrecimiento = response.data.tasaCrecimiento

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetClientesPerdidosNuevos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetClientesPerdidosNuevos/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.clientesPerdidos = response.data.clientesPerdidos
                this.clientes = response.data.clientes
                this.rotacionClientes = response.data.rotacionClientes

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetFacturacion() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetFacturacion/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.facturacionActual = response.data.facturacionActual
                this.facturacionPasado = response.data.facturacionPasado
                this.facturacion = response.data.facturacion

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetTicketPromedio() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetTicketPromedio/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.facturacionBruta = response.data.facturacionBruta
                this.volumenVentas = response.data.volumenVentas
                this.ticketPromedio = response.data.ticketPromedio

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetCostoAdquisicionClientes() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get('Indicadores/GetCostoAdquisicionClientes/' + this.selectedAnio + '/' + this.selectedMes.label)
                console.log(response)

                this.sueldoVentas = response.data.sueldoVentas
                this.sueldoMarketing = response.data.sueldoMarketing
                this.nuevosClientes = response.data.nuevosClientes
                this.cac = response.data.cac

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
    }
}
</script>