<template>
    <div>
        <canvas id="myChart" ref="chartCanvas"></canvas>
        <q-page-sticky position="bottom-right" :offset="[18, 18]">
            <q-btn fab icon="mdi-file-pdf-box" color="red" @click="exportToPDF()">
                <q-tooltip>
                    Generar PDF
                </q-tooltip>
            </q-btn>
        </q-page-sticky>
    </div>
</template>
  
<script>
import Chart from "chart.js";
import jsPDF from 'jspdf';

export default {
    props: {
        chartData: Object, // Los datos para tu gráfica
        chartTitle: String,
    },
    mounted() {
        // Renderiza la gráfica cuando el componente se monta
        // this.renderChart();
    },
    computed: {
        nombre() {
            return this.$store.state.nombrePDF;
        },
        fecha() {
            return this.$store.state.fechaPDF;
        },
    },
    watch: {
        chartData: {
            deep: true,
            handler(newData) {
                this.updateChart(newData);
            },
        },
    },

    methods: {
        exportToPDF() {
            const canvas = document.querySelector('#myChart');
            const pdf = new jsPDF({
                orientation: 'landscape', // Configura la orientación como horizontal
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = '#662e91'

            //LOGO
            const rutaImagen = require('../../assets/Logos-05.png');
            pdf.addImage(rutaImagen, 'PNG', xCabecera, 5, 58, 20);

            //DATOS DE LA EMPRESA Y POLIZA
            pdf.setFontSize(14);
            pdf.setFont('helvetica', 'bold');
            pdf.text('INDICADORES | ' + this.nombre, 280, yCabecera, { align: 'right' });
            pdf.setFontSize(10);
            // doc.text(objEmpresa, 270, yCabecera += 5, { align: 'right' });

            // doc.text(objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
            let periodo = 'PERIODO DE ' + this.fecha;
            pdf.text(periodo, 280, yCabecera += 5, { align: 'right' });
            // Establecer el fondo del lienzo como blanco
            pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 10, 30, 270, 140);
            pdf.save('GRAFICO ' + this.nombre + ' DE ' + this.fecha + '.pdf');
        },
        renderChart() {
            const ctx = this.$refs.chartCanvas.getContext("2d");

            new Chart(ctx, {
                type: "bar", // Cambia esto al tipo de gráfica que necesites (bar, line, pie, etc.)
                data: this.chartData,
                options: {
                    // Opciones de configuración de la gráfica (títulos, etiquetas, colores, etc.)
                },
            });
        },

        updateChart(newData) {
            const plugin = {
                id: 'customCanvasBackgroundColor',
                beforeDraw: (chart, args, options) => {
                    const { ctx } = chart;
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = options.color || '#99ffff';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                }
            };

            const ctx = this.$refs.chartCanvas.getContext("2d");
            if (this.chart) {
                this.chart.destroy(); // Destruye la gráfica existente antes de crear una nueva
            }
            this.chart = new Chart(ctx, {
                type: "bar",
                data: newData,
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        text: this.chartTitle
                    },
                    plugins: {
                        customCanvasBackgroundColor: {
                            color: 'white',
                        }
                    }
                },
                plugins: [plugin],
            });
        },
    },
};
</script>
