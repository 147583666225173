import jsPDF from 'jspdf';
import moment from 'moment'

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { currency: 'USD' });
}

function formtaoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY HH:mm:ss')
}

const Reporte = async (reporte) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = '#027BE3'

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(19);
            doc.setFont('helvetica', 'bold');
            doc.text('Indicadores', 20, 20, { align: 'left' });
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            let fecha = new Date()
            doc.text(formtaoFecha(fecha), 190, 20, { align: 'right' });

            // VENTAS
            const width = 201;
            const height = 7;
            doc.setFillColor(color);
            doc.rect(20, 25, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('VENTAS', 23, 30, { align: 'left' });
            doc.text(formatoNumerico(reporte.ventas.ventas), 189, 30, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('Meta de ventas mensuales / Ventas realizadas al mes * 100 = Ventas', 20, 36, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let ventas = formatoNumerico(reporte.ventas.metaVentasMes) + ' / ' + formatoNumerico(reporte.ventas.ventasMes) + ' * 100 = ' + formatoNumerico(reporte.ventas.ventas)
            doc.text(ventas, 20, 40, { align: 'left' });

            // CRECIMIENTO
            doc.setFillColor(color);
            doc.rect(20, 50, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('CRECIMIENTO', 23, 55, { align: 'left' });
            doc.text(formatoNumerico(reporte.crecimiento.crecimiento), 189, 55, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('(Ingresos mes en curso – ingresos mes anterior) / Ingresos mes anterior X 100', 20, 61, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let crecimiento = '(' + formatoNumerico(reporte.crecimiento.ingresoMesActual) + ' - ' + formatoNumerico(reporte.crecimiento.ingresoMesAnterior) + ') / ' + formatoNumerico(reporte.crecimiento.ingresoMesAnterior) + ' * 100 = ' + formatoNumerico(reporte.crecimiento.crecimiento)
            doc.text(crecimiento, 20, 65, { align: 'left' });

            // TASA DE VENTAS POR VENDEDOR
            doc.setFillColor(color);
            doc.rect(20, 75, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('TASA DE VENTAS POR VENDEDOR', 23, 80, { align: 'left' });
            doc.text(formatoNumerico(reporte.tasaVentas.tasaVentas), 189, 80, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('Número de ventas del vendedor en un periodo determinado / Número ventas totales en el mismo periodo * 100', 20, 86, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let tasaVentas = formatoNumerico(reporte.tasaVentas.numVentasVendedor) / formatoNumerico(reporte.tasaVentas.numVentasTotal) + ' * 100 = ' + formatoNumerico(reporte.tasaVentas.tasaVentas)
            doc.text(tasaVentas, 20, 90, { align: 'left' });

            // TASA DE CRECIMIENTO DE VENTAS
            doc.setFillColor(color);
            doc.rect(20, 100, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('TASA DE CRECIMIENTO DE VENTAS', 23, 105, { align: 'left' });
            doc.text(formatoNumerico(reporte.tasaCrecimiento.tasaCrecimiento), 189, 105, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('(Ventas periodo actual – ventas del periodo anterior) / ventas periodo anterior * 100.', 20, 111, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let tasaCrecimiento = '(' + formatoNumerico(reporte.tasaCrecimiento.ventasActual) + ' - ' + formatoNumerico(reporte.tasaCrecimiento.ventasAnterior) + ') / ' + formatoNumerico(reporte.tasaCrecimiento.ventasActual) + ' * 100 = ' + formatoNumerico(reporte.tasaCrecimiento.tasaCrecimiento)
            doc.text(tasaCrecimiento, 20, 115, { align: 'left' });

            // CLIENTES PERDIDOS VS CLIENTES NUEVOS
            doc.setFillColor(color);
            doc.rect(20, 125, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('CLIENTES PERDIDOS VS CLIENTES NUEVOS', 23, 130, { align: 'left' });
            doc.text(formatoNumerico(reporte.rotacionClientes.rotacionClientes), 189, 130, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('(Número total de clientes perdidos) / (Número total de clientes a principio de mes) * 100 = %Rotación de clientes perdidos.', 20, 136, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let rotacionClientes = formatoNumerico(reporte.rotacionClientes.clientesPerdidos) + ' / ' + formatoNumerico(reporte.rotacionClientes.clientes) + ' * 100 = ' + formatoNumerico(reporte.rotacionClientes.rotacionClientes)

            doc.text(rotacionClientes, 20, 140, { align: 'left' });

            // CLIENTES PERDIDOS VS CLIENTES NUEVOS
            doc.setFillColor(color);
            doc.rect(20, 150, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('FACTURACIÓN', 23, 155, { align: 'left' });
            doc.text(formatoNumerico(reporte.facturacion.facturacion), 189, 155, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('(Facturación mes actual – facturación mes pasado) / (Facturación mes pasado) * 100 = facturación mensual.', 20, 161, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let facturacion = '(' + formatoNumerico(reporte.facturacion.facturacionActual) + ' - ' + formatoNumerico(reporte.facturacion.facturacionPasado) + ') / ' + formatoNumerico(reporte.facturacion.facturacionPasado) + ' * 100 = ' + formatoNumerico(reporte.facturacion.facturacion)
            doc.text(facturacion, 20, 165, { align: 'left' });


            // TICKET PROMEDIO
            doc.setFillColor(color);
            doc.rect(20, 175, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('TICKET PROMEDIO', 23, 180, { align: 'left' });
            doc.text(formatoNumerico(reporte.ticketPromedio.ticketPromedio), 189, 180, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('(Facturación bruta / Volumen de ventas) = Ticket promedio.', 20, 186, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let ticketPromedio = '(' + formatoNumerico(reporte.ticketPromedio.facturacionBruta) + ' / ' + formatoNumerico(reporte.ticketPromedio.volumenVentas) + ') = ' + formatoNumerico(reporte.ticketPromedio.ticketPromedio)
            doc.text(ticketPromedio, 20, 190, { align: 'left' });

            // COSTO DE ADQUISICIÓN DE CLIENTES
            doc.setFillColor(color);
            doc.rect(20, 200, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#fff')
            doc.text('COSTO DE ADQUISICIÓN DE CLIENTES', 23, 205, { align: 'left' });
            doc.text(formatoNumerico(reporte.cac.cac), 189, 205, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('(Sueldos equipo de ventas + Suelos equipo de marketing + Gastos de publicidad) / Nuevos clientes adquiridos = CAC', 20, 211, { align: 'left' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(11);
            doc.setTextColor('#000')
            let cac = '(' + formatoNumerico(reporte.cac.sueldoVentas) + ' + ' + formatoNumerico(reporte.cac.sueldoMarketing) + ' + ' + formatoNumerico(reporte.cac.gastosPublicidad) + ') / ' + formatoNumerico(reporte.cac.gastosPublicidad) + ' = ' + formatoNumerico(reporte.cac.cac)
            doc.text(cac, 20, 215, { align: 'left' });

            const base64 = doc.output('datauristring');
            resolve(base64);

        } catch (error) {
            console.log(error)
        }
    })
}

export {
    Reporte
}