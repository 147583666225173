import { render, staticRenderFns } from "./ChartComponent.vue?vue&type=template&id=f8b94bc8"
import script from "./ChartComponent.vue?vue&type=script&lang=js"
export * from "./ChartComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPageSticky,QBtn,QTooltip});
